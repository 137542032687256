<template>
  <div class="dashbase-input">
    <span
      class="d-block font-16 font-600 darktext--text relative"
      v-if="!noTitle"
      >{{ title }}

      <span class="error--text" v-if="isRequired">*</span>
    </span>
    <v-select
      dense
      :items="items"
      height="45px"
      :hide-details="true"
      :rules="rules"
      :placeholder="placeholder"
      class="radius-5 mt-1"
      outlined
      :readonly="readOnly"
      background-color="#F8F8F8"
      :multiple="multiple"
      :item-text="itemText"
      :item-value="itemValue"
      :value="value"
      @input="$emit('input', $event)"      
    >
    <template v-if="isPhone" v-slot:item="{item}">
      <span >{{item.name}}  (+{{item.phone_code}})</span>

    </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
   value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },    
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
  },
};
</script>

<style lang="scss">
.dashbase-input {
  .v-input__slot{
    overflow: hidden;
  }
  span{
    min-height: 26px;
  }
  fieldset{
    border-color: #d8d8d87e !important
  }
}
</style>
