<template>
  <div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="socialForm">
      <div :class="isMd ? 'text-center': 'd-flex align-center justify-space-between'">
        <span
          class="d-block font-24 font-700"
          :class="isMobile ? 'font-18 mb-2' : 'font-24 '"
          >{{ $t("forms.editSocialMediaLinks") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="$t('forms.save')"
          :closeTitle="$t('forms.discard')"
          @close="$emit('close')"
        ></dialog-btns>
      </div>

      <!-- links -->
      <v-row class="my-0 py-1" v-for="(social, i) in socials" :key="i">
        <!-- type -->

        <v-col cols="12" md="4" class="py-1 hidden">
          <base-select
            v-model="social.id"
            :noTitle="true"
            :items="socials"
            hidden
          ></base-select>
        </v-col>
        <!-- value -->
        <v-col cols="12" md="12" class="pb-1">
          <!-- We should get it from doctor_social->value -->
          <base-input
            v-model="social.value"
            :isClear="true"
            placeholder=""
            class="pt-2"
            :title="social.title"
            :noTitle="false"
            :rules="[]"
          ></base-input>
        </v-col>
      </v-row>

      <!-- add new -->
      <!-- <v-row class="my-0 py-1">
      <v-col cols="12" md="4" class="py-1">
        <base-select  placeholder="Add new" :noTitle="true" ></base-select>
      </v-col>
      <v-col cols="12" md="8" class="py-1">
        <base-input :isClear="true" placeholder="" :noTitle="true"></base-input>
      </v-col>
    </v-row> -->

      <dialog-btns
      v-if="!isMobile"
        :btnTitle="
          completeProfile ? $t('forms.saveContinue') : $t('forms.save')
        "
        :closeTitle="completeProfile ? '' : $t('forms.discard')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import BaseInput from "../BaseInput.vue";
import BaseSelect from "../BaseSelect.vue";
import DialogBtns from "./DialogBtns.vue";
export default {
  props: {
    completeProfile: {
      type: Boolean,
      default: false,
    },
  },
  components: { DialogBtns, BaseSelect, BaseInput },
  data: () => ({
    socials: [],
    valid: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.socialForm.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let formData = new FormData();
      this.socials.forEach((social, idx) => {
        formData.append(`social[${idx}][id]`, social.id);
        formData.append(`social[${idx}][value]`, social.value);
      });
      let { data } = await this.$axios.post("social/media/update", formData);

      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        if(this.completeProfile){
          this.$emit('nextHandler')
        }
        this.$emit("updateData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async getSocials() {
      let { data } = await this.$axios.get("social/media");
      if (data) {
        this.socials = data.data;
        this.socials = this.socials.map((social) => {
          return {
            ...social,
            value: !social.doctor_social || social.doctor_social.value == 'null' ?'': social.doctor_social.value,
            title: social.social_name,
          };
        });
      }
    },
  },
  async created() {
    await this.getSocials();
  },
};
</script>

<style></style>
