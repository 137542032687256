<template>
  <div>
    <div class="pa-4 white radius-15">
      <social-media
        :completeProfile="true"
        @nextHandler="$emit('nextHandler',5)"
      ></social-media>
    </div>
  </div>
</template>

<script>
import SocialMedia from "../../../dashboard/components/dialogs/SocialMedia.vue";
export default {
  components: { SocialMedia },
};
</script>

<style></style>
